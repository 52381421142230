import { absolutize } from './utils';

export class DocumentCache {
  private cache: Map<string, string | null> = new Map();

  public async getDocument(url: string): Promise<Document> {
    const urlKey = absolutize(url);

    let page = this.cache.get(urlKey);
    if(!page) {
      page = await this.fetchUrl(url);
      this.cache.set(urlKey, page);
    }

    return new DOMParser().parseFromString(page, 'text/html');
  }

  private async fetchUrl(url: string): Promise<string> {
    // TODO: make save with try catch
    const page = await fetch(url);

    return await page.text();
  }
}