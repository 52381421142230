import type { PageSide, SlotId } from './models/router.types';


export class FlipState {
    private pageElement: HTMLElement;
    private showingSide: PageSide;

    constructor(doc: Document) {
        this.pageElement = doc.getElementById('flip-card')!;
        console.log('is flipped', this.isCurrentlyFlipped());
        if(this.isCurrentlyFlipped()) {
            this.showingSide = 'Back';
        } else {
            this.showingSide = 'Front';
        }

        this.addEventListeners();
    }

    private isCurrentlyFlipped(): boolean {
        return this.pageElement.classList.contains('flipped');
    }

    public get activeSide(): PageSide {
        return this.showingSide;
    }

    public flipTo(slotId: SlotId | undefined) {
        if (!slotId || !slotId.startsWith('page--')) {
            return;
        }

        this.pageElement.classList.toggle('flip', slotId === 'page--back');
        this.setActiveSide(slotId)
    }

    private addEventListeners() {
        this.pageElement.addEventListener('transitionend', (event) => {
            if (event.target === this.pageElement) {
                this.pageElement.classList.toggle('flipped', this.showingSide === 'Back');
            }
        });
    }

    private setActiveSide(slotId: SlotId) {
        if (slotId === 'page--back') {
            this.showingSide = 'Back';
        } else if (slotId === 'page--front') {
            this.showingSide = 'Front';
        }
    }
}