const POINTER_MOVE_UPDATE_INTERVAL_MS = 500;

type Coordinate = {
  x: number;
  y: number;
};

function interpolateCoordinates(
  a: Coordinate,
  b: Coordinate,
  n: number
): Coordinate {
  return {
    x: a.x + (b.x - a.x) * n,
    y: a.y + (b.y - a.y) * n,
  };
}

function coordinatesMatch(a: Coordinate, b: Coordinate, precision = 1000) {
  return (
    Math.round((a.x - b.x) * precision) === 0 && Math.round((a.y - b.y) * precision) === 0
  );
}

export function initBackgroundPointerUpdate() {
  let updateInterval: ReturnType<typeof setInterval> | undefined = undefined;

  const { body } = document;

  let current: Coordinate = {
    x: body.clientWidth / 2,
    y: body.clientHeight / 2,
  };
  let target: Coordinate = {
    ...current,
  };

  function updatePointer() {
    // Update `current` to be closer to the target
    current = interpolateCoordinates(current, target, 0.3);
    const { clientWidth, clientHeight } = body;
    body.style.backgroundPosition = `calc(50% + ${
      current.x - clientWidth / 2
    }px) calc(50% + ${current.y - clientHeight / 2}px)`;

    if (coordinatesMatch(current, target)) {
      // Wait until the mouse moves again
      clearInterval(updateInterval);
      console.log("updatePointer is pausing");
    }
  }

  window.addEventListener(
    "pointermove",
    (e) => {
      target = {
        x: e.clientX,
        y: e.clientY,
      };
      clearInterval(updateInterval);
      updateInterval = setInterval(
        updatePointer,
        POINTER_MOVE_UPDATE_INTERVAL_MS
      );
    },
    { passive: true }
  );
}
