import { RouteListener } from '../models/router.types';

export class FooterNavigation implements RouteListener {
  private navLinks: NodeListOf<HTMLAnchorElement>;

  constructor(footer: HTMLElement) {
    this.navLinks = footer.querySelectorAll('.footer-menu a')!;
  }

  public update(url: string): void {
    this.navLinks.forEach((anchor) => {
      const link: HTMLLIElement = anchor.parentElement as HTMLLIElement;
      link.classList.toggle('active', anchor.href === url);
    });
  }
}