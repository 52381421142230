import { Filterable } from './filterable.interface';

export class FilterMenu {
    private filterMenu: HTMLElement;
    private observers: Set<Filterable> = new Set();

    constructor(filterMenu: HTMLElement, filters: string[]){
        this.filterMenu = filterMenu;
        this.initMenuItems(filters);
    }

    public addObserver(filterable: Filterable) {
        this.observers.add(filterable);
    }

    public destroy() {
        this.filterMenu.querySelectorAll<HTMLElement>('a').forEach((anchor) => anchor.removeEventListener('click', this.handleMenuItem));
    }

    private initMenuItems(items: string[]) {
        const menuItems = items.map((item) => {
            const li = document.createElement('li');
            const a = document.createElement('a');
            a.className = 'link-pill';
            a.innerText = item;
            li.appendChild(a);
            return li;
        });

        this.filterMenu.firstElementChild?.append(...menuItems);
        this.filterMenu.querySelectorAll<HTMLAnchorElement>('a').forEach((anchor) => anchor.addEventListener('click', this.handleMenuItem));
    }

    private handleMenuItem = (event: MouseEvent) => {
        const anchor: HTMLAnchorElement = event.target as HTMLAnchorElement;
        const liElement: HTMLLIElement = anchor.parentElement! as HTMLLIElement;

        if (liElement.classList.contains('menu-toggle')) {
            this.filterMenu.classList.toggle('open');
            liElement.classList.toggle('active');
            if (!this.filterMenu.classList.contains('open')) {
                // we just closed the menu, the ui suggests to now clear the filters.
                this.resetFilters();
            }
        } else {
            liElement.classList.toggle('active');
            this.update();
        }
    };

    private resetFilters() {
        this.filterMenu.querySelectorAll<HTMLLIElement>('li.active').forEach((li) => li.classList.remove('active'));
        this.update();
    }

    private update() {
        const filterItems = [...this.filterMenu.querySelectorAll<HTMLElement>('li.active:not(.menu-toggle)')].map((li) => {
            return li.firstChild!.textContent!;
        }).filter(Boolean);

        this.observers.forEach((observer) => observer.updateFilters(filterItems));
    }
}