import { FilterMenu } from '../menus/filter-menu';
import { Filterable } from '../menus/filterable.interface';
import { SlotId } from '../models/router.types';

import { AbstractContentController } from './abstract-content.controller';

export class PortfolioController extends AbstractContentController implements Filterable {
    private projects: HTMLElement[];
    private filterMenu: FilterMenu;

    public override attach(doc: Document, slot: SlotId): void {
        super.attach(doc, slot);
        this.projects = [...this.pageContent.querySelectorAll<HTMLElement>('.project')];
        this.filterMenu = this.initFilter();
        this.filterMenu.addObserver(this);
    }

    public override eject(): void {
        this.filterMenu.destroy();
    }

    public updateFilters(filters: string[]): void {
        for(const project of this.projects) {
            const categories = project.dataset.categories!.split(',');
            const filterApplies = categories.some((category) => filters.includes(category)) || !filters.length;

            project.classList.toggle('hidden', !filterApplies);
        }
    }

    private initFilter(): FilterMenu {
        return new FilterMenu(this.pageContent.querySelector('.filter-menu')!, this.getSetOfFilters());
    }

    private getSetOfFilters(): string[] {
        const filters: string[] = this.projects.reduce((acc: string[], project) => {
            return [...acc, ...project.dataset.categories!.split(',')];
        }, []);
        return [...new Set(filters)].sort();
    }
}