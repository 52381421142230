import { initBackgroundPointerUpdate } from "./animation/background";
import { initCustomCursor } from './animation/cursor';

import { FooterNavigation } from './menus/footer.navigation';
import { MainNavigation } from './menus/main.navigation';
import { Router } from "./router";

async function init() {
  initBackgroundPointerUpdate();
  initCustomCursor();

  const [, firstSegment] = location.pathname.split("/");
  let url = "/";
  if (["de", "en"].includes(firstSegment)) {
    url += firstSegment + "/";
  }

  const routerConfig = await (await fetch(url + "routes.json")).json();
  const router = new Router(routerConfig, document);
  const mainNavigation: MainNavigation[] = [...document.querySelectorAll<HTMLElement>('.page-header')].map((header) => new MainNavigation(header));
  const footerNavigation: FooterNavigation[] = [...document.querySelectorAll<HTMLElement>('.page-footer')].map((footer) => new FooterNavigation(footer));
  router.subscribe([...mainNavigation, ...footerNavigation]);
}

init().catch(console.log);