import { AbstractContentController } from './abstract-content.controller';
import { ContentController } from './content.controller';
import { LandingController } from './landing.controller';
import { PortfolioController } from './portfolio.controller';
import { SectionController } from './section.controller';
import { ContactController } from './contact.controller';
import { ImpressumController } from './impressum.controller';
import { NewsController } from './news.controller';
import { ProjectDetailController } from './project-detail.controller';
import { TeamController } from './team.controller';
import { TeamMemberController } from './team-member.controller';

export const mapping = {
    Basic: AbstractContentController,
    Contact: ContactController,
    Content: ContentController,
    Impressum: ImpressumController,
    Landing: LandingController,
    News: NewsController,
    Portfolio: PortfolioController,
    'Project Detail': ProjectDetailController,
    Section: SectionController,
    Team: TeamController,
    'Team Member': TeamMemberController,
};
