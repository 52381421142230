import { PageSide, RouteListener } from '../models/router.types';

export class MainNavigation implements RouteListener {
  private mainMenu: HTMLElement;
  private navLinks: NodeListOf<HTMLAnchorElement>;
  private switchButton: HTMLElement;
  private activePageName: HTMLElement;

  constructor(header: HTMLElement) {
    this.mainMenu = header.querySelector('.main-menu')!;
    this.navLinks = this.mainMenu.querySelectorAll('a');
    this.switchButton = header.querySelector('.switch')!;
    this.activePageName = header.querySelector('.active-page-name')!;
  }

  public update(url: string, activeSide: PageSide): void {
    let activePageNameFound: boolean = false;
    this.navLinks.forEach((anchor) => {
      const link: HTMLLIElement = anchor.parentElement as HTMLLIElement;
      link.classList.toggle('active', anchor.href === url);

      if(anchor.href === url) {
        this.setActivePageName(anchor);
        activePageNameFound = true;
      }
    });

    this.switchButton.classList.toggle('checked', activeSide === 'Back');
    if(!activePageNameFound) {
      this.resetActivePageName();
    }
  }

  private setActivePageName(anchor: HTMLAnchorElement): void {
    let pageName = anchor.dataset.menuName;
    if(!pageName) {
      const topMost:HTMLAnchorElement = anchor.closest('ul.top-level')?.firstChild?.firstChild as HTMLAnchorElement;
      pageName = topMost.textContent ?? '';
    }

    this.activePageName.innerText = pageName ?? '';
  }

  private resetActivePageName(): void {
    this.activePageName.innerText = '';
  }
}