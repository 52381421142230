import { SlotId } from '../models/router.types';
import { ContentController } from '../models/content.types';

export class AbstractContentController implements ContentController {
  protected pageContent: HTMLElement;

  public attach(doc: Document, slot: SlotId): void {
    this.pageContent = doc.getElementById(slot)!.querySelector<HTMLElement>('.page-content')!
    this.injectPage(slot);
    this.pageContent.parentElement!.scrollTo({top: 0, left: 0, behavior: 'instant'});
  }

  public attachChild(doc: Document): void {

  }

  public eject(): void {

  }

  public containsRouteWithin(url: string): boolean {
    return false;
  }

  private injectPage(slot: SlotId) {
    const slotPageContent = document.getElementById(slot)!.querySelector('.page-content');
    slotPageContent?.replaceWith(this.pageContent);
  }
}
