export function initCustomCursor() {
  const html = document.documentElement;
  const cursor = document.createElement('div');
  cursor.classList.add('custom-cursor');
  html.append(cursor);

  html.addEventListener('pointermove', (e) => {
    const interactive = (e.target as HTMLElement).closest(':any-link, button');
    cursor.classList.toggle('interactive', !!interactive);
    html.style.setProperty('--custom-cursor-x', `${e.clientX}px`);
    html.style.setProperty('--custom-cursor-y', `${e.clientY}px`);
  }, {passive: true});
}